<template>
    <div :class="CSSClasses" class="content-slider-block">
        <div class="block-content">
            <div class="block-content-inner" @mouseleave="handleSummaryMouseOver(0)">
                <div v-if="variant === 'contentOnly' && primarySummary" class="featured-content-item">
                    <ContentImage :images="primarySummary?.images" :image="primarySummary?.image" />
                    <ContentSummary
                        :theme="theme"
                        :label="primarySummary?.label"
                        :people="primarySummary?.people"
                        :url="primarySummary?.url"
                        :mediaSource="primarySummary?.mediaSource"
                        :syndications="primarySummary?.syndications"
                        :pageLanguage="pageLanguage"
                        @mouseover="handleSummaryMouseOver(0)"
                        :class="slideShown === 0 ? 'active' : null"
                        class="summary"
                    >
                        <template #title>
                            <Typography v-if="primarySummary?.title" variant="h2">
                                {{ $t(primarySummary?.title) }}
                            </Typography>
                        </template>

                        <template #description>
                            <RichTextElements
                                v-if="primarySummary?.description"
                                :elements="primarySummary?.description"
                                bodyStyle="serif-large"
                            >
                            </RichTextElements>
                        </template>
                    </ContentSummary>
                </div>

                <div v-else class="title-section">
                    <Eyebrow v-if="eyebrow" hasUnderline class="eyebrow" :opacity="theme">
                        {{ $t(eyebrow) }}
                    </Eyebrow>
                    <Typography v-if="sliderTitle" class="title" variant="h2-display" as="div">{{
                        $t(sliderTitle)
                    }}</Typography>
                    <RichTextElements
                        v-if="isDekNotEmpty(subheader)"
                        :elements="subheader"
                        bodyStyle="serif-large"
                        class="dek"
                    />
                    <CarnegieButton
                        v-if="button && button?.appearance && button?.label"
                        v-bind="button"
                        :theme="theme"
                        :pageLanguage="pageLanguage"
                    />
                </div>

                <div class="content-items">
                    <div v-if="variant === 'default'" class="content-item">
                        <ContentImage :images="primarySummary?.images" :image="primarySummary?.image" />
                        <ContentSummary
                            :theme="theme"
                            :label="$t(primarySummary?.label)"
                            :people="primarySummary?.people"
                            :url="primarySummary?.url"
                            :syndications="primarySummary?.syndications"
                            :mediaSource="primarySummary?.mediaSource"
                            :pageLanguage="pageLanguage"
                            class="summary"
                            :class="slideShown === getContentIndex(primarySummary, 0) ? 'active' : null"
                            @mouseover="handleSummaryMouseOver(getContentIndex(primarySummary, 0))"
                        >
                            <template #title>
                                <Typography v-if="primarySummary?.title" as="div" variant="body-large" class="heading">
                                    {{ $t(primarySummary?.title) }}
                                </Typography>
                            </template>
                        </ContentSummary>
                    </div>
                    <div
                        v-for="(content, index) in secondarySummaries || contentSummaries"
                        :key="content.id"
                        class="content-item"
                    >
                        <ContentImage :images="content?.images" :image="content?.image" />
                        <ContentSummary
                            :theme="theme"
                            :label="$t(content?.label)"
                            :people="content?.people"
                            :url="content?.url"
                            :syndications="content?.syndications"
                            :mediaSource="content?.mediaSource"
                            :pageLanguage="pageLanguage"
                            class="summary"
                            :class="slideShown === getContentIndex(content, index) ? 'active' : null"
                            @mouseover="handleSummaryMouseOver(getContentIndex(content, index))"
                        >
                            <template #title>
                                <Typography v-if="content?.title" as="div" variant="body-large" class="heading">
                                    {{ $t(content.title) }}
                                </Typography>
                            </template>
                        </ContentSummary>
                    </div>
                </div>
            </div>
        </div>
        <div class="image-slides">
            <ContentImage
                v-for="(content, index) in contentSummaries"
                :key="content?.id"
                :images="content?.images"
                :image="content?.image"
                class="slide"
                :class="slideShown === index ? 'active' : null"
                :imageType="content?.label"
            />
        </div>
    </div>
</template>

<script setup>
import { inject } from 'vue';
const { pageLanguage } = usePageDataStore();

const props = defineProps({
    variant: {
        type: String,
        default: 'default',
        validator: (value) => {
            return ['default', 'contentOnly'].includes(value);
        },
    },
    contentCards: {
        type: Array,
        default: () => [],
    },
    button: {
        type: Object,
    },
    eyebrow: {
        type: String,
    },
    sliderTitle: {
        type: String,
    },
    subheader: {
        type: Object,
    },
});

const theme = inject('tierTheme', 'light');

const highlightColor = inject('colorOverride', null);

const slideShown = ref(0);
const handleSummaryMouseOver = (slideIndex) => {
    slideShown.value = slideIndex;
};

const CSSClasses = computed(() => {
    return [`theme-${theme}`, props.variant === 'contentOnly' ? `content-only` : ''];
});
const contentSummaries = computed(() => {
    return props.contentCards?.map((item) => {
        const contentType = item?.contentCard?.content[0]?.contentType || item?.contentType;
        if (contentType) {
            if (item.contentType) {
                return getContentTypeParsers(item, item?.overrideImage)[contentType]?.();
            } else {
                return getContentTypeParsers(item.contentCard?.content[0] || item, item.contentCard?.overrideImage)[
                    contentType
                ]?.();
            }
        }
        return null;
    });
});

const secondarySummaries = computed(() => {
    return contentSummaries?.value.slice(1).map((summary) => {
        return {
            ...summary,
            isSecondary: true,
        };
    });
});

const primarySummary = computed(() => {
    return contentSummaries?.value[0];
});

function getContentIndex(content, index) {
    return content?.isSecondary ? index + 1 : index;
}
</script>

<style lang="scss" scoped>
.content-slider-block {
    @include media-query(tablet-mw) {
        display: flex;
    }

    &.theme-dark {
        .eyebrow,
        .title {
            color: color(blanc);
        }

        .dek {
            color: color(blanc, default, 0.7);
        }
    }

    .secondary {
        padding: 5.2rem var(--col-gutter);

        @include media-query(phone-mw) {
            padding-inline-start: 4.1rem;
            padding-inline-end: 4.1rem;
        }

        @include media-query(phone-mw) {
            padding-inline-start: 7.5rem;
            padding-inline-end: 5rem;
        }
    }

    .title-section {
        margin: 3.6rem 0;
        @include content-area-padding;
    }

    .title {
        margin-bottom: 1.2rem;
    }

    .dek {
        margin-bottom: 1.2rem;
        color: color(noir, default, 0.7);
    }

    .content-item,
    .featured-content-item {
        @include media-query(phone-mw) {
            margin: 0;
            border-top: 1px solid palette(border-light);
        }
        @include media-query(tablet-mw) {
            margin-inline-end: 5rem;
        }

        .summary {
            padding: 1.6rem 0 3.6rem;
            @include transition(opacity, vue-fade);

            @include media-query(phone-mw) {
                padding: 1.6rem 0 6.8rem;
            }

            @include media-query(tablet-mw) {
                padding: 2.4rem var(--col-gutter);
                opacity: 0.5;
            }

            &.active {
                @include media-query(tablet-mw) {
                    opacity: 1;
                }
            }
        }

        .content-image {
            @include media-query(tablet-mw) {
                display: none;
            }
        }
    }

    .block-content {
        @include media-query(tablet-mw) {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .content-only & {
                flex: 1;
            }
        }

        .block-content-inner {
            @include content-section;
            margin: 0;

            @include media-query(full) {
                @include padding-tb(vertical-space(4));
            }
            @include media-query(tablet-mw) {
                max-width: calc(60rem + var(--page-margin) * 2);
                margin-inline-start: auto;
            }
        }
    }

    .image-slides {
        position: relative;
        display: none;

        @include media-query(tablet-mw) {
            display: block;
            width: 50%;
            aspect-ratio: 900/846;
        }

        .slide {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            @include transition(opacity, vue-fade);

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background: v-bind(highlightColor);
                opacity: 0.2;
            }

            &.active {
                @include media-query(tablet-mw) {
                    opacity: 1;
                    transition: opacity 0.3s;
                }
            }
        }
    }

    &.content-only {
        .featured-content-item {
            border-top: none;

            .summary {
                padding-top: 7rem;
                padding-bottom: 7rem;
            }
        }

        @include media-query(tablet-mw) {
            .block-content,
            .image-slides {
                flex: 1;
                width: auto;
            }

            .block-content {
                display: flex;
                justify-content: flex-end;
                flex-direction: row;
                padding-inline-start: 0;
                padding-bottom: 2.5rem;
            }

            .block-content-inner {
                padding-inline-start: var(--page-margin);
                width: 100%;
                max-width: calc((144rem / 2));
            }

            .featured-content-item {
                border-top: none;

                .summary {
                    padding-top: 14rem;
                    padding-bottom: 14rem;
                }
            }

            .image-slides {
                aspect-ratio: auto;
            }
        }
    }
}
</style>
